import React, { useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as DownloadDropdownStyling from "./DownloadDropdown.module.scss";

const DownloadDropdown = ({ block }) => {
  const label = block.label;
  const options = block.options;
  const downloadBtnText = block.downloadButtonText;
  const [selected, setSelected] = useState();

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const onSubmit = (event) => {
    selected && window.open(selected);
    event.preventDefault();
  };

  return (
    <div className="row">
      <div
        className={`col sm-12 m-5 l-7 xl-6 ${DownloadDropdownStyling.downloadDropdownWrapper}`}
      >
        {label && <span className="field-label">{label}</span>}
        <form className="flex r-middle" onSubmit={onSubmit}>
          <select
            required
            value={selected}
            onChange={handleChange}
            className={DownloadDropdownStyling.select}
          >
            <option value="" aria-label="download" ></option>
            {options.map((option) => {
              return (
                <option
                  key={option.id}
                  label={option.text}
                  aria-label={option.text}
                  value={option.asset.url}
                >
                  {option.text}
                </option>
              );
            })}
          </select>
          <input
            type="submit"
            value={downloadBtnText}
            className={`btn btn--large btn--primary ${DownloadDropdownStyling.downloadBtn}`}
          />
        </form>
      </div>
    </div>
  );
};

export default DownloadDropdown;

DownloadDropdown.propTypes = {
  block: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        asset: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
    downloadButtonText: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  fragment DownloadDropdown on DatoCmsDownloaddropdown {
    model {
      apiKey
    }
    label
    id: originalId
    downloadButtonText
    options {
      id: originalId
      text
      asset {
        url
      }
    }
  }
`;
